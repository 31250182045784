import type { Params } from 'next/dist/shared/lib/router/utils/route-matcher'
import type { GetStaticProps } from 'next'
import { StoryblokComponent, useStoryblokState } from '@storyblok/react'
import { CookieBanner, GoogleTagManagerScripts, SEO, StoryblokStoryContext } from '@/components/dynamic'
import { MainMenuContext } from '@/components/dynamic/MainMenu'
import {
  MainMenuItems,
  PageStory,
  StoryblokStaticData,
  getMainMenuItems,
  getStoryblokStaticData,
  getStoryblokStory,
} from '@/services/Storyblok.service'

export type PageProps = {
  story: PageStory | null;
  data?: StoryblokStaticData;
  mainMenuItems?: MainMenuItems;
  currentPath?: string;
};

export default function Page({ story, data, mainMenuItems, currentPath }: PageProps) {
  const state = useStoryblokState(story, {
    resolveRelations: ['global_reference.reference', 'redirect.target_story'],
  })

  return (
    <>
      <SEO content={story?.content} canonical={currentPath} />
      <StoryblokStoryContext.Provider value={data?.__next_ssg_data ?? {}}>
        <MainMenuContext.Provider value={{ currentPath: currentPath ?? null, items: mainMenuItems ?? [] }}>
          <StoryblokComponent blok={state?.content} />
          <CookieBanner />
          <GoogleTagManagerScripts />
        </MainMenuContext.Provider>
      </StoryblokStoryContext.Provider>
    </>
  )
}

export const getStaticProps: GetStaticProps = async (context: Params) => {
  const version = (context?.preview) ? 'draft' : 'published'

  const story = await getStoryblokStory<PageStory>('errors/404', version)

  const data = await getStoryblokStaticData<PageProps>(Page, { story })
  const mainMenuItems = await getMainMenuItems()

  return { props: { story, data, mainMenuItems }, revalidate: 60 * 60 }
}
